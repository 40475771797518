import { http } from "../api/http";
import Utils from "../utils";

export const runbookService = {
  get,
  execute,
  validate,
};

function get(requestParameters) {
  let url = `/runbook?${Utils.objectToQueryString(requestParameters)}`;

  return http.get(url).then((response) => {
    return response;
  });
}

function execute(requestBody) {
  let url = `/runbook/execute`;

  return http.post(url, JSON.stringify(requestBody)).then((response) => {
    return response;
  });
}

function validate(requestBody) {
  let url = `/runbook/validate`;

  return http.post(url, JSON.stringify(requestBody)).then((response) => {
    return response;
  });
}
