import { connectorConstants } from "../constants/connector.constants";

export function connector(state = {}, action) {
  switch (action.type) {
    case connectorConstants.GET_REQUEST:
    case connectorConstants.UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        request: action.payload,
        error: false,
        success: false,
      };
    case connectorConstants.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        paginatedResult: action.payload,
      };
    case connectorConstants.UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case connectorConstants.GET_FAILURE:
    case connectorConstants.UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        paginatedResult: {},
      };
    default:
      return state;
  }
}
