export const runbookConstants = {
  GET_REQUEST: "GET_RUNBOOK_REQUEST",
  GET_SUCCESS: "GET_RUNBOOK_SUCCESS",
  GET_FAILURE: "GET_RUNBOOK_FAILURE",
  EXECUTE_REQUEST: "EXECUTE_RUNBOOK_REQUEST",
  EXECUTE_SUCCESS: "EXECUTE_RUNBOOK_SUCCESS",
  EXECUTE_FAILURE: "EXECUTE_RUNBOOK_FAILURE",
  VALIDATE_REQUEST: "VALIDATE_RUNBOOK_REQUEST",
  VALIDATE_SUCCESS: "VALIDATE_RUNBOOK_SUCCESS",
  VALIDATE_FAILURE: "VALIDATE_RUNBOOK_FAILURE",
};
