import { clientVariableService } from "../services/clientVariable.service";
import { clientVariableConstants } from "../constants/clientVariable.constants";

export const clientVariableActions = {
  get,
  update,
};

function get(requestParameters) {
  return (dispatch) => {
    dispatch(request(requestParameters));

    clientVariableService.get(requestParameters).then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request(requestParameters) {
    return {
      type: clientVariableConstants.GET_REQUEST,
      payload: requestParameters,
    };
  }
  function success(paginatedResult) {
    return {
      type: clientVariableConstants.GET_SUCCESS,
      payload: paginatedResult,
    };
  }
  function failure() {
    return { type: clientVariableConstants.GET_FAILURE };
  }
}

function update(requestParameters) {
  return (dispatch) => {
    dispatch(request(requestParameters));

    clientVariableService.update(requestParameters).then(
      (response) => {
        dispatch(success());
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request(requestParameters) {
    return {
      type: clientVariableConstants.UPDATE_REQUEST,
      payload: requestParameters,
    };
  }
  function success() {
    return { type: clientVariableConstants.UPDATE_SUCCESS };
  }
  function failure() {
    return {
      type: clientVariableConstants.UPDATE_FAILURE,
    };
  }
}
