import { runbookConstants } from "../constants/runbook.constants";

export function runbook(state = {}, action) {
  switch (action.type) {
    case runbookConstants.GET_REQUEST:
      return {
        ...state,
        loading: true,
        request: action.payload,
      };
    case runbookConstants.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        paginatedResult: action.payload,
      };
    case runbookConstants.GET_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case runbookConstants.EXECUTE_REQUEST:
      return {
        ...state,
        loadingExecute: true,
        request: action.payload,
        executeResponse: null,
        executeError: null,
      };
    case runbookConstants.EXECUTE_SUCCESS:
      return {
        ...state,
        loadingExecute: false,
        executeResponse: action.payload,
        executeError: null,
      };
    case runbookConstants.EXECUTE_FAILURE:
      return {
        ...state,
        loadingExecute: false,
        executeResponse: null,
        executeError: action.payload,
      };
    case runbookConstants.VALIDATE_REQUEST:
      return {
        ...state,
        loadingValidate: true,
        request: action.payload,
        validateResponse: null,
        validateError: null,
      };
    case runbookConstants.VALIDATE_SUCCESS:
      return {
        ...state,
        loadingValidate: false,
        validateResponse: action.payload,
        validateError: null,
      };
    case runbookConstants.VALIDATE_FAILURE:
      return {
        ...state,
        loadingValidate: false,
        validateResponse: null,
        validateError: action.payload,
      };
    default:
      return state;
  }
}
