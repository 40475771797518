import { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { TbStepInto } from "react-icons/tb";
import { Badge } from "react-bootstrap";
import Utils from "../../utils";

function RunbookStepModal(props) {
  const [step, setStep] = useState({});

  useEffect(() => {
    setStep(props.data);

    if (props.data) {
      setStep((originalStep) => ({
        ...originalStep,
        action: <Badge bg="primary">{originalStep.action}</Badge>,
        connector: originalStep.connector
          ? `${originalStep.connector.connectorId} (${
              originalStep.connector.system
            } - ${originalStep.connector.loginEndpoint}${
              originalStep.connector.companyId
                ? ` - ${originalStep.connector.companyId}`
                : ""
            })`
          : null,
      }));
    }

    // eslint-disable-next-line
  }, [props.data]);

  return (
    <Modal show={props.show} onHide={props.onClose} size={"lg"}>
      <Modal.Header closeButton>
        <Modal.Title>
          <TbStepInto className="mb-1 me-2" />
          {step.stepId} Details
          {step.system && (
            <>
              {" "}
              - <small>{step.system}</small>
            </>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {step &&
          Object.entries(step).map(
            ([key, value], i) =>
              (value || value === 0) && (
                <Container key={i}>
                  <Row
                    key={key}
                    className={`${i === 0 ? "pb-3" : "py-3"} border-bottom`}
                  >
                    <Col md={4}>
                      <b>{Utils.fixCamelCase(key)}</b>
                    </Col>
                    <Col>{value}</Col>
                  </Row>
                </Container>
              )
          )}
      </Modal.Body>
    </Modal>
  );
}

export default RunbookStepModal;
