import React from "react";
import Title from "../common/Title";
import { RiAccountCircleFill } from "react-icons/ri";
import Avatar from "react-avatar";
import Badge from "react-bootstrap/Badge"

function Profile(props) {
  return (
    <>
      <div className="align-items-center">
        <Title text="Profile" icon={<RiAccountCircleFill className="mb-1" />} />
        <div className="profile-content">
          <div className="flex-column d-flex align-items-center mb-3">
            <Avatar className="align-items-center" name={props.user.firstName + " " + props.user.lastName}
              size="100" round="50px" />
            <span className="value">{props.user.email}</span>
          </div>
          <div className="flex-column d-flex align-items-center mb-3">
            <div className="align-items-center mb-3">
              <span className="value">{props.user.firstName} {props.user.lastName}</span>
            </div>
            {
              props.user.isAdmin
                ? (<div className="align-items-center mb-3">
                  <h6><Badge variant="primary"> Admin </Badge></h6>
                </div>)
                : (<></>)
            }
            {
              props.user.isSuperUser
                ? (<div className="profile-detail d-flex mb-3">
                  <h6><Badge variant="primary"> Super User </Badge></h6>
                </div>)
                : (<></>)
            }
            {
              props.user.clientName
                ? (<div className="profile-detail d-flex">
                  <span className="label d-flex">{props.user.clientName}</span>
                </div>)
                : (<></>)
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
