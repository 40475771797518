import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Title from "../common/Title";
import Grid from "../common/Grid";
import { GiMegaphone } from "react-icons/gi";
import Utils from "../../utils";
import { broadcastMessageActions } from "../../actions/broadcastMessage.actions";
import BroadcastMessageGridFilter from "./BroadcastMessageGridFilter";
import { MdDelete, MdEdit } from "react-icons/md";
import ConfirmModal from "../common/ConfirmModal";
import { Alert } from "react-bootstrap";

function BroadcastMessageGrid(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const broadcastMessage = useSelector((state) => state.broadcastMessage);
  const [confirmDeleteModalShow, setConfirmDeleteModalShow] = useState(false);
  const [deleteMessageId, setDeleteMessageId] = useState("");

  const filterInitialState = {
    variant: "",
    displayedOn: "",
  };
  const [filter, setFilter] = useState(filterInitialState);

  const actions = [
    {
      text: "Edit",
      url: "edit?messageId={id}",
      icon: <MdEdit className="mb-1 me-2" />,
    },
    {
      text: "Delete",
      event: (id) => {
        setDeleteMessageId(id);
        setConfirmDeleteModalShow(true);
      },
      icon: <MdDelete className="mb-1 me-2" />,
    },
  ];

  useEffect(() => {
    let queryParams = Utils.getQueryStringParams(location);
    queryParams["clientId"] = props.user.clientId;
    queryParams["clientGuid"] = props.user.clientGuid;

    dispatch(broadcastMessageActions.get(queryParams));

    // Initialize filter from URL
    setFilter({
      variant: queryParams["variant"],
      displayedOn: queryParams["displayedOn"],
    });

    // eslint-disable-next-line
  }, [location]);

  const handlePageClick = (pageNumber) => {
    let queryParams = Utils.getQueryStringParams(location);
    queryParams.current_page = pageNumber;

    refreshParams(queryParams);
  };

  const handleSortClick = (orderBy, orderDirection) => {
    let queryParams = Utils.getQueryStringParams(location);
    queryParams.order_by = orderBy;
    queryParams.order_direction = orderDirection;

    refreshParams(queryParams);
  };

  const handleSearch = (filter) => {
    let queryParams = Utils.getQueryStringParams(location);
    queryParams.current_page = 0;

    for (const property in filter) {
      if (filter[property]) queryParams[property] = filter[property];
      else delete queryParams[property];
    }

    refreshParams(queryParams);
  };

  const handleClearFilters = () => {
    handleSearch(filterInitialState);
  };

  const refreshParams = (queryParamsObj) => {
    navigate(`/messages?${Utils.objectToQueryString(queryParamsObj)}`);
  };

  const handleDeleteBroadcastMessage = () => {
    setConfirmDeleteModalShow(false);
    let requestParameters = {
      messageId: deleteMessageId,
      clientid: props.user.clientId,
      clientGuid: props.user.clientGuid,
    };

    dispatch(broadcastMessageActions.remove(requestParameters));
  };

  let displayColumns = [
    "title",
    "content",
    "variant",
    "displayStartDate",
    "displayEndDate",
    "createdByUserName",
  ];
  if (props.user.isSuperUser) displayColumns.push("clientId");

  const data = broadcastMessage.paginatedResult?.data.map((m) => {
    return {
      ...m,
      displayStartDate: Utils.convertToDateOnly(m.displayStartDate),
      displayEndDate: Utils.convertToDateOnly(m.displayEndDate),
      variant: (
        <Alert variant={m.variant} className="py-0 px-1 m-0">
          {Utils.fixCamelCase(m.variant)}
        </Alert>
      ),
    };
  });

  return (
    <>
      <Title
        text="Broadcast Messages"
        icon={<GiMegaphone className="mb-1" />}
      />

      <BroadcastMessageGridFilter
        filter={filter}
        onSearch={handleSearch}
        onClearFilters={handleClearFilters}
      />

      <Link to={"add"} className="btn btn-primary float-end mb-2">
        Add Broadcast Message
      </Link>

      <Grid
        data={data}
        displayColumns={displayColumns}
        idColumn={"messageId"}
        actions={actions}
        pageNumber={broadcastMessage.paginatedResult?.pageNumber ?? 0}
        totalPages={broadcastMessage.paginatedResult?.totalPages ?? 0}
        loading={broadcastMessage.loading}
        onPageChange={handlePageClick}
        onSort={handleSortClick}
      ></Grid>

      <ConfirmModal
        show={confirmDeleteModalShow}
        title="Delete Broadcast Message"
        question={`Are you sure you want to delete broadcast message?`}
        noButtonText="No"
        yesButtonText="Yes, please"
        handleClose={() => {
          setConfirmDeleteModalShow(false);
        }}
        handleConfirm={() => {
          handleDeleteBroadcastMessage();
        }}
      />
    </>
  );
}

export default BroadcastMessageGrid;
