import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import NoPage from "../common/NoPage";

function PrivateRoute({ children, user, onlySuperUser }) {
  const location = useLocation();
  const returnUrl = location.pathname + location.search;

  if (!user) {
    return (
      <Navigate to={`/login?returnUrl=${encodeURIComponent(returnUrl)}`} />
    );
  }

  if (onlySuperUser && !user.isSuperUser) {
    return <NoPage />;
  }

  return <>{children}</>;
}

export default PrivateRoute;
