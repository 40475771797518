import { combineReducers } from "redux";
import { user } from "./user.reducer";
import { runbook } from "./runbook.reducer";
import { client } from "./client.reducer";
import { runbookExecutionHistory } from "./runbookExecutionHistory.reducer";
import { runbookExecutionHistoryLine } from "./runbookExecutionHistoryLine.reducer";
import { dashboard } from "./dashboard.reducer";
import { scheduler } from "./scheduler.reducer";
import { signInHelp } from "./signInHelp.reducer";
import { invitation } from "./invitation.reducer";
import { settings } from "./settings.reducer";
import { clientVariable } from "./clientVariable.reducer";
import { runbookStep } from "./runbookStep.reducer";
import { connector } from "./connector.reducer";
import { clientVariableLine } from "./clientVariableLine.reducer";
import { broadcastMessage } from "./broadcastMessage.reducer";
import { broadcastMessageReview } from "./broadcastMessageReview.reducer";

export default combineReducers({
  user,
  runbook,
  client,
  runbookExecutionHistory,
  runbookExecutionHistoryLine,
  dashboard,
  scheduler,
  signInHelp,
  invitation,
  settings,
  clientVariable,
  runbookStep,
  connector,
  clientVariableLine,
  broadcastMessage,
  broadcastMessageReview,
});
