import { http } from "../api/http";
import Utils from "../utils";

export const clientVariableService = {
  get,
  update,
};

function get(requestParameters) {
  let url = `/clientvariable?${Utils.objectToQueryString(requestParameters)}`;

  return http.get(url).then((response) => {
    return response;
  });
}

function update(requestBody) {
  let url = `/clientvariable/update`;

  return http.patch(url, JSON.stringify(requestBody)).then((response) => {
    return response;
  });
}
