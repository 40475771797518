import { userConstants } from "../constants/user.constants";

export function user(state = {}, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loading: true,
        loginFailed: false,
      };
    case userConstants.LOGIN_SUCCESS:
      return state;
    case userConstants.LOGIN_FAILURE:
      return {
        loading: false,
        user: undefined,
        loginFailed: true,
      };
    case userConstants.LOGOUT_REQUEST:
      return {
        loading: true,
      };
    case userConstants.LOGOUT_SUCCESS:
    case userConstants.LOGOUT_FAILURE:
      return {
        loading: false,
      };
    case userConstants.CREATE_REQUEST:
      return {
        loading: true,
        createFailed: false,
        createSuccess: false,
      };
    case userConstants.CREATE_SUCCESS:
      return {
        loading: false,
        createFailed: false,
        createSuccess: true,
      };
    case userConstants.CREATE_FAILURE:
      return {
        loading: false,
        createFailed: true,
        createSuccess: false,
      };
    default:
      return state;
  }
}
