import React from "react";
import { Pagination } from "react-bootstrap";

const Paging = ({ currentPage, totalPages, onPageChange }) => {
  const displayPages = 10; // Number of pages to display before and after the current page
  const pageItems = [];
  const halfDisplay = Math.floor(displayPages / 2);

  let startPage = Math.max(0, currentPage - halfDisplay);
  let endPage = Math.min(totalPages - 1, startPage + displayPages - 1);

  // Adjust if the endPage is less than the desired count
  if (endPage - startPage + 1 < displayPages) {
    startPage = Math.max(0, endPage - displayPages + 1);
  }

  for (let i = startPage; i <= endPage; i++) {
    pageItems.push(
      <Pagination.Item
        key={i}
        active={i === currentPage}
        onClick={() => onPageChange(i)}
      >
        {i + 1}
      </Pagination.Item>
    );
  }

  return (
    <Pagination className="d-flex justify-content-center mt-2">
      <Pagination.First onClick={() => onPageChange(0)} />
      <Pagination.Prev
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 0}
      />

      {pageItems}

      <Pagination.Next
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages - 1}
      />
      <Pagination.Last onClick={() => onPageChange(totalPages - 1)} />
    </Pagination>
  );
};

export default Paging;
