import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Title from "../common/Title";
import Subtitle from "../common/Subtitle";
import { CgDetailsMore } from "react-icons/cg";
import { CiBoxList } from "react-icons/ci";
import ReactFlow from "reactflow";
import "reactflow/dist/style.css";
import { GeneralNode, getNodes, getEdges } from "./RunbookDetails.viewModel";
import { runbookStepActions } from "../../actions/runbookStep.actions";
import Utils from "../../utils";
import { Placeholder } from "react-bootstrap";
import useRunbookTitle from "../../hooks/runbookTitle";
import RunbookStepModal from "./RunbookStepModal";

function RunbookDetails(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const runbookStepReducer = useSelector((state) => state.runbookStep);
  const { runbookTitle, runbookTitleLoading } = useRunbookTitle();
  const [modalStepId, setModalStepId] = useState("");

  const nodeTypes = {
    generalNode: GeneralNode,
  };

  useEffect(() => {
    const runbookId = Utils.getQueryStringParams(location)["runbookId"];

    dispatch(runbookStepActions.get({ runbookId }));

    // eslint-disable-next-line
  }, []);

  const handleStepClick = (stepId) => {
    setModalStepId(stepId);
  };

  const handleModalClose = () => {
    setModalStepId("");
  };

  const initialNodes = runbookStepReducer.response
    ? getNodes(runbookStepReducer.response, handleStepClick)
    : [];

  const initialEdges = runbookStepReducer.response
    ? getEdges(runbookStepReducer.response)
    : [];

  const loading = runbookTitleLoading || runbookStepReducer.loading;

  return (
    <>
      <Title text="Runbook Details" icon={<CgDetailsMore className="mb-1" />} />
      {!loading && (
        <Subtitle
          text={runbookTitle}
          icon={<CiBoxList className="mb-1" />}
        />
      )}

      {loading && (
        <Placeholder as={"div"} style={{ height: "100%" }} animation="glow">
          <Placeholder xs={12} size="lg" style={{ height: "100%" }} />
        </Placeholder>
      )}

      {!loading && (
        <>
          <div style={{ height: "100%" }} className="mt-4">
            <ReactFlow
              nodes={initialNodes}
              edges={initialEdges}
              proOptions={{ hideAttribution: true }}
              nodeTypes={nodeTypes}
            ></ReactFlow>
          </div>
          <RunbookStepModal
            show={modalStepId !== ""}
            onClose={handleModalClose}
            data={
              modalStepId !== ""
                ? initialNodes.filter((s) => s.id === modalStepId)[0].data.step
                : {}
            }
          />
        </>
      )}
    </>
  );
}

export default RunbookDetails;
