import { http } from "../api/http";

export const broadcastMessageReviewService = {
  create,
};

async function create(requestBody) {
  const url = `/broadcastmessagereview/create`;

  try {
    const response = await http.post(url, JSON.stringify(requestBody));
    return response;
  } catch (error) {
    throw error;
  }
}
