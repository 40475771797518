import { broadcastMessageReviewService } from "../services/broadcastMessageReview.service";
import { broadcastMessageReviewConstants } from "../constants/broadcastMessageReview.constants";

export const broadcastMessageReviewActions = {
  create,
};

function create(requestParameters) {
  return (dispatch) => {
    dispatch(request(requestParameters));

    broadcastMessageReviewService.create(requestParameters).then(
      (response) => {
        dispatch(success());
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request(requestParameters) {
    return {
      type: broadcastMessageReviewConstants.CREATE_BROADCAST_MESSAGE_REVIEW_REQUEST,
      payload: requestParameters,
    };
  }
  function success() {
    return {
      type: broadcastMessageReviewConstants.CREATE_BROADCAST_MESSAGE_REVIEW_SUCCESS,
    };
  }
  function failure() {
    return {
      type: broadcastMessageReviewConstants.CREATE_BROADCAST_MESSAGE_REVIEW_FAILURE,
    };
  }
}
