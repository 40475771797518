import { invitationConstants } from "../constants/invitation.constants";

export function invitation(state = {}, action) {
  switch (action.type) {
    case invitationConstants.OPEN_MODAL:
      return {
        ...state,
        loading: false,
        request: null,
        success: false,
        visible: true,
      };
    case invitationConstants.SEND_REQUEST:
      return {
        ...state,
        loading: true,
        request: action.payload,
        success: false,
      };
    case invitationConstants.SEND_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case invitationConstants.SEND_FAILURE:
      return {
        ...state,
        loading: false,
        sucess: false,
      };
    case invitationConstants.CLOSE_MODAL:
      return {
        ...state,
        visible: false,
      };
    default:
      return state;
  }
}
