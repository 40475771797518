import { http } from "../api/http";

export const userService = {
  login,
  recoverPassword,
  validateCode,
  updatePassword,
  create,
};

function login(email, password) {
  let url = `/user/login`;

  return http
    .post(
      url,
      JSON.stringify({
        email,
        password,
      })
    )
    .then((response) => {
      return response;
    });
}

function recoverPassword(email) {
  let url = `/user/recoverpassword`;

  return http
    .post(
      url,
      JSON.stringify({
        email,
      })
    )
    .then((response) => {
      return response;
    });
}

function validateCode(email, code) {
  let url = `/user/validatecode`;

  return http
    .post(
      url,
      JSON.stringify({
        email,
        code,
      })
    )
    .then((response) => {
      return response;
    });
}

function updatePassword(token, newPassword) {
  let url = `/user/updatepassword`;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return http
    .patch(
      url,
      JSON.stringify({
        newPassword,
      }),
      config
    )
    .then((response) => {
      return response;
    });
}

function create(
  token,
  firstName,
  lastName,
  email,
  clientId,
  clientGuid,
  password
) {
  let url = `/user/create`;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return http
    .post(
      url,
      JSON.stringify({
        firstName,
        lastName,
        email,
        clientId,
        clientGuid,
        password,
      }),
      config
    )
    .then((response) => {
      return response;
    });
}
