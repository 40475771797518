import React, { useState } from "react";
import { Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Utils from "../../utils";
import Paging from "./Paging";
import { FaSort } from "react-icons/fa";
import NoResults from "./NoResults";
import PlaceHolderLines from "./PlaceHolderLines";

const Grid = ({
  data,
  displayColumns,
  idColumn,
  idColumn2,
  actions,
  pageNumber,
  totalPages,
  loading,
  placeHolderLinesCount,
  onPageChange,
  onSort,
}) => {
  const columns = displayColumns ?? Object.keys(data[0] || {});
  const [orderBy, setOrderBy] = useState(-1);
  const [orderDirection, setOrderDirection] = useState(1);

  const handleSortClick = (orderByColumn) => {
    let orderDirectionResult;

    if (orderByColumn === orderBy) {
      orderDirectionResult = orderDirection * -1;
    } else {
      orderDirectionResult = 1;
    }

    setOrderBy(orderByColumn);
    setOrderDirection(orderDirectionResult);

    onSort(orderByColumn, orderDirectionResult === 1 ? "Asc" : "Desc");
  };

  const checkVisibility = (visibilityConditions, dataObject) => {
    // Check if the conditions array is empty
    if (!visibilityConditions || visibilityConditions.length === 0) {
      return true; // If no conditions, consider it visible
    }

    // Evaluate each visibility condition
    return visibilityConditions.every((condition) => {
      const { key, operation, value } = condition;

      // Ensure the key exists in the dataObject
      if (dataObject.hasOwnProperty(key)) {
        // Perform the specified operation
        switch (operation) {
          case ">":
            return dataObject[key] > value;
          case "<":
            return dataObject[key] < value;
          case ">=":
            return dataObject[key] >= value;
          case "<=":
            return dataObject[key] <= value;
          case "==":
            // eslint-disable-next-line eqeqeq
            return dataObject[key] == value; // Loose equality
          case "===":
            return dataObject[key] === value; // Strict equality
          default:
            return false; // Invalid operation
        }
      } else {
        return false; // Key doesn't exist in dataObject
      }
    });
  };

  return (
    <div>
      {loading && <PlaceHolderLines count={placeHolderLinesCount ?? 11} />}
      {!loading && (
        <div>
          {totalPages > 0 && (
            <>
              <Table hover striped>
                <thead>
                  <tr>
                    {columns.map((column) => (
                      <th key={column} className="text-nowrap">
                        {onSort && (
                          <Button
                            variant="link"
                            className="text-decoration-none text-dark ps-0 fw-bold"
                            onClick={() =>
                              handleSortClick(
                                Utils.fixCamelCase(column).replaceAll(" ", "")
                              )
                            }
                          >
                            {Utils.fixCamelCase(column)}
                            <FaSort className="ms-2 mb-1 text-primary" />
                          </Button>
                        )}
                        {!onSort && <>{Utils.fixCamelCase(column)}</>}
                      </th>
                    ))}
                    {actions && <th></th>}
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index}>
                      {columns.map((column) => (
                        <td key={column}>{item[column]}</td>
                      ))}
                      {actions && (
                        <td className="text-nowrap">
                          {actions.map((action, actionIndex) => (
                            <div key={actionIndex} className="d-inline">
                              {checkVisibility(
                                action.visibilityConditions,
                                item
                              ) && (
                                <>
                                  {action.url && (
                                    <Link
                                      to={action.url
                                        .replace("{id}", item[idColumn])
                                        .replace("{id2}", item[idColumn2])}
                                      className="text-decoration-none text-secondary"
                                      title={action.text}
                                    >
                                      {action.icon}
                                    </Link>
                                  )}
                                  {action.event && (
                                    <span
                                      onClick={() => {
                                        action.event(item[idColumn]);
                                        return false;
                                      }}
                                      as={"a"}
                                      className="text-decoration-none text-secondary"
                                      title={action.text}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {action.icon}
                                    </span>
                                  )}
                                </>
                              )}
                            </div>
                          ))}
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>

              {totalPages > 1 && (
                <Paging
                  currentPage={pageNumber}
                  totalPages={totalPages}
                  onPageChange={onPageChange}
                />
              )}
            </>
          )}
        </div>
      )}
      {!loading && totalPages === 0 && (
        <div className="d-flex justify-content-center">
          <NoResults />
        </div>
      )}
    </div>
  );
};

export default Grid;
