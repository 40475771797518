import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Title from "../common/Title";
import Grid from "../common/Grid";
import { GiAlarmClock } from "react-icons/gi";
import Utils from "../../utils";
import { runbookSchedulerActions } from "../../actions/runbookScheduler.actions";
import moment from "moment";
import cronstrue from "cronstrue";
import SchedulerGridFilter from "./SchedulerGridFilter";
import { MdError, MdDelete, MdEdit } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import ConfirmModal from "../common/ConfirmModal";

function SchedulerGrid(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const schedulerReducer = useSelector((state) => state.scheduler);
  const [confirmDeleteModalShow, setConfirmDeleteModalShow] = useState(false);
  const [deleteSchedulerId, setDeleteSchedulerId] = useState("");

  const filterInitialState = {
    schedulerId: "",
    runbookId: "",
    runbookName: "",
    createdOn: "",
    nextExecution: "",
    cronError: "",
    createdBy: "",
  };
  const [filter, setFilter] = useState(filterInitialState);

  const actions = [
    {
      text: "Edit",
      url: "edit?schedulerId={id}",
      icon: <MdEdit className="mb-1 me-2" />,
    },
    {
      text: "Delete",
      event: (id) => {
        setDeleteSchedulerId(id);
        setConfirmDeleteModalShow(true);
      }, // For event dispach
      icon: <MdDelete className="mb-1 me-2" />,
    },
  ];

  useEffect(() => {
    let queryParams = Utils.getQueryStringParams(location);
    queryParams["clientId"] = props.user.clientId;
    queryParams["clientGuid"] = props.user.clientGuid;

    dispatch(runbookSchedulerActions.get(queryParams));

    // Initialize filter from URL
    setFilter({
      schedulerId: queryParams["schedulerId"],
      runbookId: queryParams["runbookId"],
      runbookName: queryParams["runbookName"],
      createdOn: queryParams["createOn"],
      nextExecution: queryParams["nextExecution"],
      cronError: queryParams["cronError"],
      createdBy: queryParams["createdBy"],
    });

    // eslint-disable-next-line
  }, [location]);

  const handlePageClick = (pageNumber) => {
    let queryParams = Utils.getQueryStringParams(location);
    queryParams.current_page = pageNumber;

    refreshParams(queryParams);
  };

  const handleSortClick = (orderBy, orderDirection) => {
    let queryParams = Utils.getQueryStringParams(location);
    queryParams.order_by = orderBy;
    queryParams.order_direction = orderDirection;

    refreshParams(queryParams);
  };

  const handleSearch = (filter) => {
    let queryParams = Utils.getQueryStringParams(location);
    queryParams.current_page = 0;

    for (const property in filter) {
      if (filter[property]) queryParams[property] = filter[property];
      else delete queryParams[property];
    }

    refreshParams(queryParams);
  };

  const handleClearFilters = () => {
    handleSearch(filterInitialState);
  };

  const refreshParams = (queryParamsObj) => {
    navigate(`/scheduler?${Utils.objectToQueryString(queryParamsObj)}`);
  };

  const handleDeleteScheduler = () => {
    setConfirmDeleteModalShow(false);
    let requestParameters = {
      schedulerId: deleteSchedulerId,
      clientid: props.user.clientId,
      clientGuid: props.user.clientGuid,
    };

    dispatch(runbookSchedulerActions.remove(requestParameters));
  };

  let displayColumns = [
    "schedulerId",
    "runbookId",
    "runbookName",
    "cron",
    "nextExecution",
    "cronError",
    "createdBy",
  ];
  if (props.user.isSuperUser) displayColumns.push("clientId");

  const data = schedulerReducer.paginatedResult?.data.map((r) => {
    return {
      ...r,
      nextExecution: r.nextExecution
        ? moment(Utils.convertToDateTime(r.nextExecution)).fromNow()
        : "-",
      cron: (
        <>
          {r.cron}
          <br />
          <small>({cronstrue.toString(r.cron)})</small>
        </>
      ),
      cronError: r.cronError ? (
        <span className="text-danger">
          <MdError className="me-1 mb-1" /> Error
        </span>
      ) : (
        <span className="text-success">
          <TiTick className="me-1 mb-1" /> No Error
        </span>
      ),
    };
  });

  return (
    <>
      <Title text="My Schedulers" icon={<GiAlarmClock className="mb-1" />} />

      <SchedulerGridFilter
        filter={filter}
        onSearch={handleSearch}
        onClearFilters={handleClearFilters}
      />

      <Grid
        data={data}
        displayColumns={displayColumns}
        idColumn={"schedulerId"}
        actions={actions}
        pageNumber={schedulerReducer.paginatedResult?.pageNumber ?? 0}
        totalPages={schedulerReducer.paginatedResult?.totalPages ?? 0}
        loading={schedulerReducer.loading}
        onPageChange={handlePageClick}
        onSort={handleSortClick}
      ></Grid>

      <ConfirmModal
        show={confirmDeleteModalShow}
        title="Delete scheduler"
        question={`Are you sure you want to delete ${deleteSchedulerId}?`}
        noButtonText="No"
        yesButtonText="Yes, please"
        handleClose={() => {
          setConfirmDeleteModalShow(false);
        }}
        handleConfirm={() => {
          handleDeleteScheduler();
        }}
      />
    </>
  );
}

export default SchedulerGrid;
