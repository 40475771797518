import React, { useEffect, useState } from "react";
import Title from "../common/Title";
import { GiAlarmClock } from "react-icons/gi";
import Utils from "../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Button, Spinner, Alert } from "react-bootstrap";
import { Cron } from "react-js-cron";
import "react-js-cron/dist/styles.css";
import cronstrue from "cronstrue";
import { runbookSchedulerActions } from "../../actions/runbookScheduler.actions";
import { useDispatch, useSelector } from "react-redux";
import PlaceHolderLines from "../common/PlaceHolderLines";

function SchedulerForm(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [schedulerId, setSchedulerId] = useState("");
  const [runbookId, setRunbookId] = useState("");
  const [runbookGuid, setRunbookGuid] = useState("");
  const [cron, setCron] = useState("* * * * *");
  const schedulerReducer = useSelector((state) => state.scheduler);

  useEffect(() => {
    let queryParams = Utils.getQueryStringParams(location);
    if (queryParams["schedulerId"]) {
      queryParams["clientId"] = props.user.clientId;
      queryParams["clientGuid"] = props.user.clientGuid;
      setSchedulerId(queryParams["schedulerId"]);
      dispatch(runbookSchedulerActions.get(queryParams));
    } else {
      setSchedulerId("");
      setRunbookId(queryParams["runbookId"]);
      setRunbookGuid(queryParams["runbookGuid"]);
    }
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (
      schedulerId &&
      schedulerReducer.paginatedResult &&
      schedulerReducer.paginatedResult.data &&
      schedulerReducer.paginatedResult.data[0]?.runbookId
    ) {
      setRunbookId(schedulerReducer.paginatedResult.data[0].runbookId);
      setCron(schedulerReducer.paginatedResult.data[0].cron);
    }
    // eslint-disable-next-line
  }, [schedulerReducer]);

  useEffect(() => {
    if (schedulerReducer.success) {
      navigate("/scheduler");
    }
    // eslint-disable-next-line
  }, [schedulerReducer.success]);

  const handleSaveScheduler = () => {
    if (schedulerId) {
      dispatch(
        runbookSchedulerActions.update({
          schedulerId,
          clientId: props.user.clientId,
          clientGuid: props.user.clientGuid,
          cron,
        })
      );
    } else {
      dispatch(
        runbookSchedulerActions.create({
          runbookId,
          runbookGuid,
          clientId: props.user.clientId,
          clientGuid: props.user.clientGuid,
          cron,
        })
      );
    }
  };

  const operation = !schedulerId ? "Add" : "Update";

  return (
    <>
      <Title
        text={`${operation} Scheduler`}
        icon={<GiAlarmClock className="mb-1" />}
      />

      {schedulerReducer.loading && <PlaceHolderLines count={4} />}

      {!schedulerReducer.loading && (
        <>
          {!schedulerReducer.success && (
            <>
              {!schedulerReducer.loading && schedulerReducer.error && (
                <Alert variant={"danger"}>
                  An error has occurred. Please try again later.
                </Alert>
              )}

              <Form.Group controlId="formRunbookId" className="mb-3">
                <Form.Label>Runbook Id</Form.Label>
                <Form.Control
                  type="text"
                  value={runbookId}
                  onChange={(e) => setRunbookId(e.target.value)}
                  disabled
                />
              </Form.Group>

              <Form.Group controlId="formCron" className="mb-3">
                <Form.Label>Cron expression</Form.Label>
                <Cron value={cron} setValue={setCron} />
                <small>
                  {cron && (
                    <>
                      {cron} ({cronstrue.toString(cron)})
                    </>
                  )}
                </small>
              </Form.Group>

              <Button
                variant="outline-secondary"
                type="submit"
                className="me-3 mt-3"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>

              <Button
                variant="primary"
                type="submit"
                className="mt-3"
                onClick={handleSaveScheduler}
                disabled={schedulerReducer.loading}
              >
                {!schedulerReducer.loading ? (
                  <>{operation} Scheduler</>
                ) : (
                  <Spinner size="sm" />
                )}
              </Button>
            </>
          )}
        </>
      )}
    </>
  );
}

export default SchedulerForm;
