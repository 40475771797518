import React, { useEffect, useState } from "react";
import Title from "../common/Title";
import { FaDatabase } from "react-icons/fa";
import Utils from "../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Button, Spinner, Alert } from "react-bootstrap";
import { connectorActions } from "../../actions/connector.actions";
import { useDispatch, useSelector } from "react-redux";
import PlaceHolderLines from "../common/PlaceHolderLines";
import PasswordInput from "../common/PasswordInput";

function ConnectorForm(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const connectorInitialState = {
    connectorId: "",
    system: "",
    loginEndpoint: "",
    senderId: "",
    senderPassword: "",
    userId: "",
    userPassword: "",
    companyId: "",
    oAuthClientId: "",
    clientSecret: "",
  };
  const [connector, setConnector] = useState(connectorInitialState);
  const connectorReducer = useSelector((state) => state.connector);

  useEffect(() => {
    let queryParams = Utils.getQueryStringParams(location);
    if (queryParams["connectorId"]) {
      queryParams["clientId"] = props.user.clientId;
      queryParams["clientGuid"] = props.user.clientGuid;
      dispatch(connectorActions.get(queryParams));
    } else {
      setConnector(connectorInitialState);
    }
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (
      connectorReducer.paginatedResult &&
      connectorReducer.paginatedResult.data &&
      connectorReducer.paginatedResult.data[0]
    ) {
      setConnector(connectorReducer.paginatedResult.data[0]);
    }
    // eslint-disable-next-line
  }, [connectorReducer]);

  useEffect(() => {
    if (connectorReducer.success) {
      navigate("/connector");
    }
    // eslint-disable-next-line
  }, [connectorReducer.success]);

  const handleSaveConnector = () => {
    if (connector.connectorId) {
      let connectorToUpdate = connector;
      connectorToUpdate.clientId = props.user.clientId;
      connectorToUpdate.clientGuid = props.user.clientGuid;
      dispatch(connectorActions.update(connectorToUpdate));
    }
  };

  const operation = !connector.connectorId ? "Add" : "Update";

  return (
    <>
      <Title
        text={`${operation} Connector`}
        icon={<FaDatabase className="mb-1" />}
      />

      {connectorReducer.loading && <PlaceHolderLines count={12} />}

      {!connectorReducer.loading && (
        <>
          {!connectorReducer.success && (
            <>
              {!connectorReducer.loading && connectorReducer.error && (
                <Alert variant={"danger"}>
                  An error has occurred. Please try again later.
                </Alert>
              )}

              <Form.Group controlId="formConnectorId" className="mb-3">
                <Form.Label>Connector Id</Form.Label>
                <Form.Control
                  type="text"
                  value={connector.connectorId}
                  onChange={(e) =>
                    setConnector((c) => ({ ...c, connectorId: e.target.value }))
                  }
                  disabled
                />
              </Form.Group>

              <Form.Group controlId="formDescription" className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  value={connector.description}
                  onChange={(e) =>
                    setConnector((c) => ({ ...c, description: e.target.value }))
                  }
                />
              </Form.Group>

              <Form.Group controlId="formSystem" className="mb-3">
                <Form.Label>System</Form.Label>
                <Form.Control
                  type="text"
                  value={connector.system}
                  onChange={(e) =>
                    setConnector((c) => ({ ...c, system: e.target.value }))
                  }
                />
              </Form.Group>

              <Form.Group controlId="formLoginEndpoint" className="mb-3">
                <Form.Label>Login Endpoint</Form.Label>
                <Form.Control
                  type="text"
                  value={connector.loginEndpoint}
                  onChange={(e) =>
                    setConnector((c) => ({
                      ...c,
                      loginEndpoint: e.target.value,
                    }))
                  }
                />
              </Form.Group>

              <Form.Group controlId="formSenderId" className="mb-3">
                <Form.Label>Sender Id</Form.Label>
                <Form.Control
                  type="text"
                  value={connector.senderId}
                  onChange={(e) =>
                    setConnector((c) => ({ ...c, senderId: e.target.value }))
                  }
                  autoComplete="new-password"
                />
              </Form.Group>

              <Form.Group controlId="formSenderPassword" className="mb-3">
                <Form.Label>Sender Password</Form.Label>
                <PasswordInput
                  value={connector.senderPassword}
                  onChange={(e) =>
                    setConnector((c) => ({
                      ...c,
                      senderPassword: e.target.value,
                    }))
                  }
                />
              </Form.Group>

              <Form.Group controlId="formUserId" className="mb-3">
                <Form.Label>User Id</Form.Label>
                <Form.Control
                  type="text"
                  value={connector.userId}
                  onChange={(e) =>
                    setConnector((c) => ({ ...c, userId: e.target.value }))
                  }
                  autoComplete="new-password"
                />
              </Form.Group>

              <Form.Group controlId="formUserPassword" className="mb-3">
                <Form.Label>User Password</Form.Label>
                <PasswordInput
                  value={connector.userPassword}
                  onChange={(e) =>
                    setConnector((c) => ({
                      ...c,
                      userPassword: e.target.value,
                    }))
                  }
                />
              </Form.Group>

              <Form.Group controlId="formCompanyId" className="mb-3">
                <Form.Label>Company Id</Form.Label>
                <Form.Control
                  type="text"
                  value={connector.companyId}
                  onChange={(e) =>
                    setConnector((c) => ({ ...c, companyId: e.target.value }))
                  }
                />
              </Form.Group>

              <Form.Group controlId="formOAuthClientId" className="mb-3">
                <Form.Label>OAuth ClientId</Form.Label>
                <PasswordInput
                  value={connector.oAuthClientId}
                  onChange={(e) =>
                    setConnector((c) => ({
                      ...c,
                      oAuthClientId: e.target.value,
                    }))
                  }
                />
              </Form.Group>

              <Form.Group controlId="formOAuthClientSecret" className="mb-3">
                <Form.Label>OAuth ClientSecret</Form.Label>
                <PasswordInput
                  value={connector.clientSecret}
                  onChange={(e) =>
                    setConnector((c) => ({
                      ...c,
                      clientSecret: e.target.value,
                    }))
                  }
                />
              </Form.Group>

              <Form.Group controlId="formOAuthTenantId" className="mb-3">
                <Form.Label>OAuth TenantId</Form.Label>
                <Form.Control
                  type="text"
                  value={connector.tenantId}
                  onChange={(e) =>
                    setConnector((c) => ({ ...c, tenantId: e.target.value }))
                  }
                />
              </Form.Group>

              <Button
                variant="outline-secondary"
                type="submit"
                className="me-3 mt-3"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>

              <Button
                variant="primary"
                type="submit"
                className="mt-3"
                onClick={handleSaveConnector}
                disabled={connectorReducer.loading}
              >
                {!connectorReducer.loading ? (
                  <>{operation} connector</>
                ) : (
                  <Spinner size="sm" />
                )}
              </Button>
            </>
          )}
        </>
      )}
    </>
  );
}

export default ConnectorForm;
