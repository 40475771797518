import { runbookService } from "../services/runbook.service";
import { runbookConstants } from "../constants/runbook.constants";

export const runbookActions = {
  get,
  execute,
  validate,
};

function get(requestParameters) {
  return (dispatch) => {
    dispatch(request(requestParameters));

    runbookService.get(requestParameters).then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request(requestParameters) {
    return { type: runbookConstants.GET_REQUEST, payload: requestParameters };
  }
  function success(paginatedResult) {
    return { type: runbookConstants.GET_SUCCESS, payload: paginatedResult };
  }
  function failure() {
    return { type: runbookConstants.GET_FAILURE };
  }
}

function execute(requestBody) {
  return (dispatch) => {
    dispatch(request(requestBody));

    runbookService.execute(requestBody).then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(requestBody) {
    return {
      type: runbookConstants.EXECUTE_REQUEST,
      payload: requestBody,
    };
  }
  function success(response) {
    return { type: runbookConstants.EXECUTE_SUCCESS, payload: response };
  }
  function failure(error) {
    return {
      type: runbookConstants.EXECUTE_FAILURE,
      payload: error.response.data.title,
    };
  }
}

function validate(requestBody) {
  return (dispatch) => {
    dispatch(request(requestBody));

    runbookService.validate(requestBody).then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(requestBody) {
    return {
      type: runbookConstants.VALIDATE_REQUEST,
      payload: requestBody,
    };
  }
  function success(response) {
    return { type: runbookConstants.VALIDATE_SUCCESS, payload: response };
  }
  function failure(error) {
    return {
      type: runbookConstants.VALIDATE_FAILURE,
      payload: error.response.data.title,
    };
  }
}
