import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Title from "../common/Title";
import Grid from "../common/Grid";
import Utils from "../../utils";
import { clientVariableLineActions } from "../../actions/clientVariableLine.actions";
import { MdDelete, MdEdit } from "react-icons/md";
import { CiTextAlignJustify } from "react-icons/ci";
import Subtitle from "../common/Subtitle";
import { LuVariable } from "react-icons/lu";
import ConfirmModal from "../common/ConfirmModal";
import VariableLineGridFilter from "./VariableLineGridFiler";
import useClientVariableTitle from "../../hooks/clientVariableTitle";

function VariableLineGrid(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const clientVariableLine = useSelector((state) => state.clientVariableLine);
  const [confirmDeleteModalShow, setConfirmDeleteModalShow] = useState(false);
  const [deleteVariableLineId, setDeleteVariableLineId] = useState("");
  const { clientVariableTitle, clientVariableTitleLoading } =
    useClientVariableTitle();

  const filterInitialState = {
    variableLineId: "",
    variableKey: "",
    variableValue: "",
  };
  const [filter, setFilter] = useState(filterInitialState);

  const actions = [
    {
      text: "Edit",
      url: "./edit?variableLineId={id}",
      icon: <MdEdit className="mb-1 me-2" />,
    },
    {
      text: "Delete",
      event: (id) => {
        setDeleteVariableLineId(id);
        setConfirmDeleteModalShow(true);
      },
      icon: <MdDelete className="mb-1 me-2" />,
    },
  ];

  useEffect(() => {
    let queryParams = Utils.getQueryStringParams(location);
    if (queryParams["variableId"]) {
      queryParams["clientId"] = props.user.clientId;
      queryParams["clientGuid"] = props.user.clientGuid;

      dispatch(clientVariableLineActions.get(queryParams));

      // Initialize filter from URL
      setFilter({
        variableLineId: queryParams["variableLineId"],
        variableKey: queryParams["variableKey"],
        variableValue: queryParams["variableValue"],
      });
    }

    // eslint-disable-next-line
  }, [location]);

  const handlePageClick = (pageNumber) => {
    let queryParams = Utils.getQueryStringParams(location);
    queryParams.current_page = pageNumber;

    refreshParams(queryParams);
  };

  const handleSortClick = (orderBy, orderDirection) => {
    let queryParams = Utils.getQueryStringParams(location);
    queryParams.order_by = orderBy;
    queryParams.order_direction = orderDirection;

    refreshParams(queryParams);
  };

  const handleSearch = (filter) => {
    let queryParams = Utils.getQueryStringParams(location);
    queryParams.current_page = 0;

    for (const property in filter) {
      if (filter[property]) queryParams[property] = filter[property];
      else delete queryParams[property];
    }

    refreshParams(queryParams);
  };

  const handleClearFilters = () => {
    handleSearch(filterInitialState);
  };

  const refreshParams = (queryParamsObj) => {
    navigate(`/variable/line?${Utils.objectToQueryString(queryParamsObj)}`);
  };

  const handleDeleteClientVariableLine = () => {
    setConfirmDeleteModalShow(false);
    let requestParameters = {
      variableLineId: deleteVariableLineId,
      clientid: props.user.clientId,
      clientGuid: props.user.clientGuid,
    };

    dispatch(clientVariableLineActions.remove(requestParameters));
  };

  let displayColumns = [
    "variableId",
    "variableLineId",
    "variableKey",
    "variableValue",
  ];

  const data = clientVariableLine.paginatedResult?.data;

  return (
    <>
      <Title
        text="My Variable Lines"
        icon={<CiTextAlignJustify className="mb-1" />}
      />
      {!clientVariableLine.loading &&
        !clientVariableTitleLoading &&
        clientVariableLine.request?.variableId && (
          <Subtitle
            text={clientVariableTitle}
            icon={<LuVariable className="mb-1" />}
          />
        )}

      <VariableLineGridFilter
        filter={filter}
        onSearch={handleSearch}
        onClearFilters={handleClearFilters}
      />

      {clientVariableLine.request?.variableId && (
        <Link
          to={`add?variableId=${clientVariableLine.request?.variableId}`}
          className="btn btn-primary float-end mb-2"
        >
          Add Variable Line
        </Link>
      )}

      <Grid
        data={data}
        displayColumns={displayColumns}
        idColumn={"variableLineId"}
        actions={actions}
        pageNumber={clientVariableLine.paginatedResult?.pageNumber ?? 0}
        totalPages={clientVariableLine.paginatedResult?.totalPages ?? 0}
        loading={clientVariableLine.loading}
        onPageChange={handlePageClick}
        onSort={handleSortClick}
      ></Grid>

      <ConfirmModal
        show={confirmDeleteModalShow}
        title="Delete Variable Line"
        question={`Are you sure you want to delete variable line ${deleteVariableLineId}?`}
        noButtonText="No"
        yesButtonText="Yes, please"
        handleClose={() => {
          setConfirmDeleteModalShow(false);
        }}
        handleConfirm={() => {
          handleDeleteClientVariableLine();
        }}
      />
    </>
  );
}

export default VariableLineGrid;
